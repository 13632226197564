import React from "react";
import "./styles/guests.css";
import img1 from "./images/shirt_front.webp";
import img2 from "./images/shirt_back.webp";
import Aos from "aos";

function GuestCard(props) {
  return (
    <div className="guest-container">
      <div className="content-guest">
        <div id="shirt-heading" data-aos="fade-down" data-aos-duration="800">
          {props.title}
        </div>
        <div
          id="shirt-description"
          data-aos="fade-right"
          data-aos-duration="800"
        >
        What was once not known to exist, the ripples in the fabric of space-time, is right here on earth in the form of a mesmerizing design. 
        In the 11th edition of the National Students’ Space Challenge, we bring to you the cool, breathable, relaxed, and easy-fit T-shirt with a stunning sketch of an astronaut surfing through the Gravitational-wave or simply G-wave, the ruffle in space that opened new eyes to the universe. What to wait for? Purchase our brand new 100 percent premium cotton T-shirt and surf through your creative imagination.
        </div>
      <div id="merch">
      <img
        src={props.image1}
        alt=""
        id="merch-img1"
        data-aos="fade-right"
        data-aos-duration="800"
      />
      <img
        src={props.image2}
        alt=""
        id="merch-img2"
        data-aos="fade-left"
        data-aos-duration="800"
      />

      </div>
      <h1 style={{marginTop: 100}}>
          Book Your T-Shirts Here
      </h1>
        <div id="merch-button">
          {/* <button
            type="button"
            className="glow-on-hover-guests"
            data-aos="zoom-in"
            data-aos-duration="800"
          >
            Available Soon
          </button> */}
          <iframe id="ts-iframe" src="https://www.townscript.com/v2/widget/nssc-2022-343023/booking" frameborder="0" height="600" width="80%"></iframe><link rel="stylesheet" href = "https://www.townscript.com/static/Bookingflow/css/ts-iframe.style.css" ></link>
        </div>
      </div>
    </div>
  );
}

function Merchandise() {
  Aos.init();
  document.body.style.overflowY = "initial";
  return (
    <div className="guest-page-container">
      <div id="guest-page-heading">MERCHANDISE</div>
      <GuestCard
        image1={img1}
        image2={img2}
        title="NSSC Special Edition T-Shirt"
      />
    </div>
  );
}

export default Merchandise;
