import React from "react";
import { Link } from "react-router-dom";
import "./styles/Event.css";
import Aos from "aos";
import data from "./Guest.json";
import headset from "./images/headset.svg";
import { renderMarkup } from "react-render-markup";


class GuestLecture extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    Aos.init();
    document.body.style.overflowY = "initial";
    let guest_details = data[this.props.id];
    let source = this.props.image;
    let timing = <>{renderMarkup(guest_details.Timing)}</>;
    let intro = <>
    { renderMarkup(guest_details.Introduction) }
    </>;
    let designation = <>
    { renderMarkup(guest_details.Designation) }
    </>;

    let venue = <>{renderMarkup(guest_details.Venue)}</>;

    let name = <>{renderMarkup(guest_details.Name)}</>;

    // let source = <>{renderMarkup(guest_details.Source)}</>;


    return (
      <div className="guest-page-container">
        <div id="know-more-heading">{guest_details.Title}</div>
        <div className="content-know-more">
          <div
            className="know-more-heading"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            INTRODUCTION
          </div>
          <div
            className="know-more-description"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            {intro}
          </div>

          <div
            className="know-more-heading"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            Speaker
          </div>
          <img src = {source} data-aos="fade-right" data-aos-duration="800" className= "speaker-image"/>

          {/* {source} */}
          <div className="know-more-description">{name}</div>
          <div
            className="know-more-heading"
            data-aos="fade-down"
            data-aos-duration="800"
          >
          </div>
          <div className="know-more-description">{designation}</div>

          

          {/* <div
            className="know-more-heading"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            Timing
          </div>
          <div className="know-more-description">{timing}</div>

          <div
            className="know-more-heading"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            Venue
          </div>
          <div className="know-more-description">{venue}</div> */}
          
          <div
            className="know-more-heading"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            CONTACT
          </div>
          <div id="know-more-description">
            <div className="eventscontact">
              <div
                className="eventscontactinside"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div>
                  <span className="imgspan">
                    <img src={headset} />
                  </span>
                </div>
                <div>
                  Shiladitya De:<br />
                  <span className="eventspan">
                    <a
                      href="tel:+919064888515"
                      style={{ textDecoration: "none", color: "#f1356d" }}
                    >
                      +91&nbsp;9064888515
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GuestLecture;
