import React, { useEffect } from "react";

function astroPhoto(){
    useEffect(() => {
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSe3GgDTbHsXTAXs3V2F3wgS7zapihawLM0rQcDDcaERaLHijA/viewform?usp=sf_link");
      }, []);
      return (
        <div>
        </div>
      );
}

export default astroPhoto;