import React from "react";
import { Link } from "react-router-dom";
import "./styles/Menu_Mobile.css";
import Sun from "./images/Sun.png";
import Mercury from "./images/mercury2.png";
import venus from "./images/venus2.png";
import earth from "./images/Earth2.png";
import mars from "./images/mars2.png";
import jupiter from "./images/Jupiter2.png";
import saturn from "./images/Saturn.png";
import uranus from "./images/uranus.png";
import backgroundGalaxy from "./images/background-compressed.jpg";

class Menu_Mobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: "hidden",
    };
  }
  show() {
    this.setState({ hidden: "" });
  }
  componentDidMount() {
    var that = this;
    setTimeout(function () {
      that.show();
    }, that.props.wait);
  }

  render() {
    return (
      <div id="nssc_eventmobile" className={this.state.hidden}>
        <div id="sun10">
          <img src={Sun} />
        </div>
        <div id="planet1">
          <Link to="/about" className="planet_inside">
            <img src={Mercury} />
            <div className="txt_events">About</div>
          </Link>
        </div>
        <div id="planet2">
          <Link to="/events" className="planet_inside">
            <img src={venus} />
            <div className="txt_events">Events</div>
          </Link>
        </div>
        <div id="planet3">
          <Link to="/guests" className="planet_inside">
            <img src={earth} />
            <div className="txt_events">Guest Lectures and Workshops</div>
          </Link>
        </div>
        <div id="planet4">
          <Link to="/gallery" className="planet_inside">
            <img src={mars} />
            <div className="txt_events">Gallery</div>
          </Link>
        </div>
        <div id="planet5">
          <Link to="/members" className="planet_inside">
            <img src={jupiter} />
            <div className="txt_events">Members</div>
          </Link>
        </div>
        <div id="planet6">
          <Link to="/sponsors" className="planet_inside">
            <img src={saturn} />
            <div className="txt_events">Sponsors</div>
          </Link>
        </div>
        <div id="planet7">
          <Link to="/contact" className="planet_inside">
            <img src={uranus} />
            <div className="txt_events">Contact Us</div>
          </Link>
        </div>
        {/* <div id="rogleg2">
          <Link to="/login" className="login-reg2">
            Login
          </Link>/
          <Link to="/register" className="login-reg2">
            Register
          </Link>
        </div> */}
        <img src={backgroundGalaxy} className="background-mobile" />
      </div>
    );
  }
}

export default Menu_Mobile;
