import React, { useEffect } from "react";

function hover(){
    useEffect(() => {
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSeFXh_27fg4hC6MoAWgdIMKN9u-hHq7Uk1lOmuw1QFGiWK-Rw/viewform?ts=63444f45");
      }, []);
      return (
        <div>
        </div>
      );
}

export default hover;