import React from "react";

const tag = "./images/sponsors/2019/TAG.png";
const bisleri = "./images/sponsors/2019/BISLERI.png";
const dubeat = "./images/sponsors/2019/DUBEAT.png";
const ohcampus = "./images/sponsors/2019/OHCAMPUS.png";
const indiafest = "./images/sponsors/2019/INDIAFEST.png";
const knowfest = "./images/sponsors/2019/KNOWFEST.png";
const thecollegefever = "./images/sponsors/2019/THECOLLEGEFEVER.png";
const d2c = "./images/sponsors/2019/DARE2COMPETE.png";
const l = "./images/sponsors/2019/L.png";
const redwings = "./images/sponsors/2019/REDWINGS.png";
const townscript = "./images/sponsors/2019/TOWNSCRIPT.png";
const iiae = "./images/sponsors/2019/IIAE.png";
const linc = "./images/sponsors/2019/LINC.png";
const oriental = "./images/sponsors/2019/ORIENTAL.png";

const Page19 = () => {
  return (
    <main class="app">
      <div class="spons-page">
        <div class="spons-inner">
          <h1>Sponsors 2019</h1>
          <div>
            <div class="spons-section">
              <h3>Title Sponsor</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.iiae.in/" target="_blank">
                    <img src={iiae} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Co Sponsored By</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.redwingsgroup.org/" target="_blank">
                    <img src={redwings} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Insurance Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://orientalinsurance.org.in/" target="_blank">
                    <img src={oriental} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Writing Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://lincpen.com/" target="_blank">
                    <img src={linc} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Gift And Goodies Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a>
                    <img src={tag} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Hydration Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a>
                    <img src={bisleri} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Merchandise Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a>
                    <img src={l} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Payment Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.townscript.com/" target="_blank">
                    <img src={townscript} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Quiz Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://unstop.com/" target="_blank">
                    <img src={d2c} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Online Media Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a>
                    <img src={dubeat} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a>
                    <img src={ohcampus} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a>
                    <img src={indiafest} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a>
                    <img src={knowfest} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a>
                    <img src={thecollegefever} alt="img" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="boxes">
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>

      <div class="spons-page"></div>
    </main>
  );
};

export default Page19;
