import React, { useEffect } from "react";

function nsscquiz(){
    useEffect(() => {
        window.location.replace("https://unstop.com/quiz/space-quiz-11-national-students-space-challenge-nssc-space-technology-students-society-iit-kharagpur-451451");
      }, []);
      return (
        <div>
        </div>
      );
}

export default nsscquiz;