import React from "react";
import Home from "./Home";
import Navbar from "./Navbar";
import Navbar_M from "./Navbar_M";
import About from "./About";
import Events_M from "./Events_M";
import Events_PC from "./Events_PC";
import Event from "./Event";
import Guests from "./Guests";
import Gallery from "./Gallery";
import Members from "./Members";
import Sponsors from "./Sponsors";
import Merchandise from "./Merchandise";
import ContactUs from "./Contact";
import Profile from "./Profile";
import Verification from "./otpVerification";
import ResetPass from "./newPassword";
import ForgetPass from "./forget_password";
import Nsscquiz from "./redirects/nsscquiz";
import CosmomathChallenge from "./redirects/sopf";
import Lift from "./redirects/lift";
import Hover from "./redirects/hover";
import Paper from "./redirects/paper";
import Study from "./redirects/study";
import Maze from "./redirects/maze";
import Gyro from "./redirects/gyro";
import Data from "./redirects/data";
import Braitenberg from "./redirects/braitenberg";
import SpaceArt from "./redirects/space";
import Volunteer from "./redirects/volunteer";
import AstroPhoto from "./redirects/astrophoto";
import "./styles/bg.css";
import LoginPage from "./LoginPage";
import Admin_profile_request from "./Admin_profile_request";
import SignupPage from "./SignupPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import GuestLec from "./GuestLecture";
import "./App.css";

function App() {
  // useEffect(() => {
  //   const apiUrl = `http://127.0.0.1:8000/spats/nssc22/backend/379883500205/api/`;
  //   fetch(apiUrl)
  //     .then((response) => response.json())
  //     .then((data) => console.log(data));
  // }, []);
  window.addEventListener("resize", () => {
    setwidth(window.innerWidth);
  });
  const [width, setwidth] = useState(window.innerWidth);
  const Events = () => {
    const breakpoint = 1100;
    return width < breakpoint ? (
      <>
        <Navbar_M />
        <Events_M />
      </>
    ) : (
      <Events_PC /> /*already has inbuilt navbar for PC*/
    );
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="login" element={
          <>
          <Navbar />
          <Navbar_M />
          <LoginPage /> 
          </>}
          />
          <Route path="register" element={<>
          <Navbar />
          <Navbar_M />
          <SignupPage /> 
          </>} /> */}
          {/* <Route path="account_verification" element={<>
          <Navbar />
          <Navbar_M />
          <Verification /> 
          </>} /> */}
          {/* <Route path="reset_password" element={<>
          <Navbar />
          <Navbar_M />
          <ResetPass /> 
          </>} />
          <Route path="forget_password" element={<>
          <Navbar />
          <Navbar_M />
          <ForgetPass /> 
          </>} /> */}
          <Route
            path="quiz"
            element={
              <>
                <Nsscquiz />
              </>
            }
          />
          <Route
            path="cosmo"
            element={
              <>
                <CosmomathChallenge />
              </>
            }
          />
          <Route path="events" element={Events()} />
          <Route
            path="hover"
            element={
              <>
                <Hover />
              </>
            }
          />
          <Route
            path="lift"
            element={
              <>
                <Lift />
              </>
            }
          />
          <Route
            path="paper"
            element={
              <>
                <Paper />
              </>
            }
          />
          <Route
            path="case"
            element={
              <>
                <Study />
              </>
            }
          />
          <Route
            path="maze"
            element={
              <>
                <Maze />
              </>
            }
          />
          <Route
            path="gyro"
            element={
              <>
                <Gyro />
              </>
            }
          />
          <Route
            path="data"
            element={
              <>
                <Data />
              </>
            }
          />
          <Route
            path="art"
            element={
              <>
                <SpaceArt />
              </>
            }
          />
          <Route
            path="astro"
            element={
              <>
                <AstroPhoto />
              </>
            }
          />
          <Route
            path="volunteer"
            element={
              <>
                <Volunteer />
              </>
            }
          />
          <Route
            path="braitenberg"
            element={
              <>
                <Braitenberg />
              </>
            }
          />
          <Route
            path="/CaseStudy"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="1" />
              </>
            }
          />
          <Route
            path="/LiftOff"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="2" />
              </>
            }
          />
          <Route
            path="/SpaceArt"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="3" />
              </>
            }
          />
          <Route
            path="/SpaceQuiz"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="4" />
              </>
            }
          />
          <Route
            path="/CosmomathChallenge"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="5" />
              </>
            }
          />
          <Route
            path="/PaperPresentation"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="6" />
              </>
            }
          />
          <Route
            path="/BraitenbergChallenge"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="7" />
              </>
            }
          />
          <Route
            path="/Hoverpod"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="8" />
              </>
            }
          />
          <Route
            path="/DataAnalytics"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="9" />
              </>
            }
          />
          <Route
            path="/Astrophotography"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="10" />
              </>
            }
          />
          <Route
            path="/Mazerunner"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="11" />
              </>
            }
          />
          <Route
            path="/Gyrobot"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Event id="12" />
              </>
            }
          />
          <Route
            path="jwst"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <GuestLec id="1" image="./images/guests/greenhouse.jpg" />
              </>
            }
          />
          <Route
            path="perseverance"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <GuestLec id="2" image="./images/guests/Priyanka.jpg" />
              </>
            }
          />
          <Route
            path="kanaksaha"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <GuestLec id="3" image="./images/guests/Kanak_Saha.png" />
              </>
            }
          />
          <Route
            path="chiefguest"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <GuestLec id="4" image="./images/guests/Debiprasad_Duari.png" />
              </>
            }
          />
          <Route
            path="about"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <About />
              </>
            }
          />
          <Route
            path="guests"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Guests />
              </>
            }
          />
          <Route
            path="gallery"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Gallery />
              </>
            }
          />
          <Route
            path="members"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Members />
              </>
            }
          />
          <Route
            path="sponsors"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Sponsors />
              </>
            }
          />
          {/* <Route
            path="merchandise"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Merchandise />
              </>
            }
          /> */}

          {/* <Route
            path="admin_request"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Admin_profile_request />
              </>
            }
          /> */}
          <Route
            path="contact"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <ContactUs />
              </>
            }
          />
          {/* <Route
            path="dashboard"
            element={
              <>
                <Navbar />
                <Navbar_M />
                <Profile />
              </>
            }
          /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
