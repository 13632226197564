import React from "react";
import Landing from "./Landing";
import Menu_PC from "./Menu_PC";
import Menu_Mobile from "./Menu_Mobile";
import "./styles/home.css";
import titleSponsor from "./images/title_sponsor.png";
import Navbar from "./Navbar";

var first = 0;
// var t = 0;

function removeLanding() {
  document.getElementById("landing_anime").style.opacity = "0";
  setTimeout(() => {
    document.getElementById("landing_anime").style.display = "none";
  }, 750);
}

class Home extends React.Component {
  render() {
    document.body.style.overflowY = "initial";

    if (first <= 1) {
      setTimeout(Landing, 200);
      setTimeout(removeLanding, 5000);
    } else {
      setTimeout(removeLanding, 200);
    }

    // if (t != 0) {
    //   clearTimeout(t);
    //   t = 0;
    // }
    // if (window.innerWidth > 1100) {
    //   t = setTimeout(function () {
    //     window.location.href = "/events";
    //   }, 5000);
    // }

    return (
      <>
        {window.innerWidth > 1100 ? (
          first <= 1 ? (
            <Menu_PC wait={5500} />
          ) : (
            <Menu_PC wait={500} />
          )
        ) : first <= 1 ? (
          <Menu_Mobile wait={5500} />
        ) : (
          <Menu_Mobile wait={500} />
        )}
        <div
          className="page"
          id="landing_anime"
          style={{ backgroundColor: "black" }}
        >
          {first++ <= 1 ? (
            <>
              <div className="boom_text">
                <br />
                National Students' Space Challenge
                <div className="boom_subtext">
                  4th - 6th Nov '22 | IIT KHARAGPUR
                </div>
                <br />
                WHERE POSSIBILITIES BEGIN!
              </div>
              <div className="title_logo_div">
                <img className="title_logo" src={titleSponsor} />
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="data"></div>
          <canvas id="canvas"></canvas>
          <div className="char manz"></div>
        </div>
      </>
    );
  }
}
export default Home;
