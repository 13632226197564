import React from "react";
import "./styles/Members.css";
import data from "./members.json";

function Cards(props) {
  return (
    <div className="container-members">
      <div className="card-members">
        <div class="border-members">
          <img src={props.url} alt="" className="img-members" />
          <h2>{props.name}</h2>
          <h3>{props.por}</h3>
          <div className="icons-members">
            <a
              href={props.email != "" ? "mailto:" + props.email : ""}
              target={props.email != "" ? "_blank" : ""}
            >
              <i className="fa-members fa fa-envelope" aria-hidden="true"></i>
            </a>
            <a href={props.facebook} target="_blank" rel="noopener noreferrer">
              <i className="fa-members fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href={props.linkedin} target="_blank" rel="noopener noreferrer">
              <i className="fa-members fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

class Members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section: "Heads",
    };
  }

  change(i) {
    this.setState({ section: i });
  }

  render() {
    document.body.style.overflowY = "initial";

    var cards = <></>;
    for (var i = 0; i < data.Heads.length; i++) {
      cards = (
        <>
          {cards}
          <Cards
            url={data.Heads[i].Image}
            name={data.Heads[i].Name}
            por={data.Heads[i].POR}
            email={data.Heads[i].email}
            facebook={data.Heads[i].FB}
            linkedin={data.Heads[i].LI}
          />
        </>
      );
    }
    var headSection = (
      <>
        <h2 className="sec-heading-members" id="heads">
          Heads
        </h2>
        <section className="por-groups">{cards}</section>
      </>
    );
    var cards = <></>;
    for (var i = 0; i < data.Subheads.length; i++) {
      cards = (
        <>
          {cards}
          <Cards
            url={data.Subheads[i].Image}
            name={data.Subheads[i].Name}
            por={data.Subheads[i].POR}
            email={data.Subheads[i].email}
            facebook={data.Subheads[i].FB}
            linkedin={data.Subheads[i].LI}
          />
        </>
      );
    }
    var subHeadSection = (
      <>
        <h2 className="sec-heading-members" id="sub-heads">
          Sub - Heads
        </h2>
        <section className="por-groups">{cards}</section>
      </>
    );

    return (
      <>
        <h1 className="heading-members">Our Team</h1>
        <div className="navbar-members">
          <a
            className="nav-buttons-members"
            onClick={() => this.change("Heads")}
          >
            Heads
          </a>
          <a
            className="nav-buttons-members"
            onClick={() => this.change("Subheads")}
          >
            Sub Heads
          </a>
        </div>
        <div className="main-container-members">
          {this.state.section == "Heads" ? headSection : subHeadSection}
        </div>
      </>
    );
  }
}

export default Members;
