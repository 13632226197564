import React from "react";

const redwings = "./images/sponsors/2022/redwings.png";
const srisatyasai = "./images/sponsors/2022/SriSatyaSai.png";
const sconto = "./images/sponsors/2022/sconto.png";
const ndciame = "./images/sponsors/2022/ndcIame.jpg";
const hoverrobotix = "./images/sponsors/2022/HoverRobotix.png";
const nojoto = "./images/sponsors/2022/Nojoto.png";
const sammachar = "./images/sponsors/2022/sammachar.png";
const knowafest = "./images/sponsors/2022/knowafest.png";
const dubeat = "./images/sponsors/2019/DUBEAT.png";
const blogadda = "./images/sponsors/2022/BLOGADDA.png";
const unstop = "./images/sponsors/2022/unstop.svg";
const av = "./images/sponsors/2022/av.jpg";
const revup = "./images/sponsors/2022/RevUp.png";
const mentorx = "./images/sponsors/2022/mentorx.png";
const fraxus3d = "./images/sponsors/2022/fraxus3d.png";
const gfg = "./images/sponsors/2021/GeeksforGeeks.png";
const scrummys ="./images/sponsors/2022/ScrummysLogo.jpg";
const fareast = "./images/sponsors/2022/FarEastLogo.png";
const KrishnaAirTravels = "./images/sponsors/2022/krishnaAirTravels.png";


const Page22 = () => {
  return (
    <main class="app">
      <div class="spons-page">
        <div class="spons-inner">
          <h1>Sponsors 2022</h1>
          <div>
            {/* <h1 id="coming_soon">COMING SOON ....</h1> */}
            <div class="spons-section">
              <h3>Title Sponsor</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.redwingsgroup.org/" target="_blank">
                    <img src={redwings} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            {/* <div class="spons-section">
              <h3>Associate Title Sponsor</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.sssutms.co.in/cms/Website" target = "_blank">
                    <img src={srisatyasai} alt="img" />
                  </a>
                </div>
              </div>
            </div> */}
            <div class="spons-section">
              <h3>Powered By</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.sconto.ai/" target="_blank">
                    <img src={sconto} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Major Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://ndcinstitute.com/" target="_blank">
                    <img src={ndciame} alt="img" />
                  </a>
                </div>
              </div>
            </div>

            <div class="spons-section">
              <h3>Events Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://hoverrobotix.com/" target="_blank">
                    <img src={hoverrobotix} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Media Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="" target="_blank">
                    <img src={sammachar} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a href="https://dubeat.com/" target="_blank">
                    <img src={dubeat} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a href="https://www.knowafest.com/explore/events" target="_blank">
                    <img src={knowafest} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a href="https://nojoto.com/" target="_blank">
                    <img src={nojoto} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a href="https://www.blogadda.com/" target="_blank">
                    <img src={blogadda} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Gifts and Goddies partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.geeksforgeeks.org/" target="_blank">
                    <img src={gfg} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a href="https://www.fraxus3d.com/" target="_blank">
                    <img src={fraxus3d} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Platform partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://unstop.com/" target="_blank">
                    <img src={unstop} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Tech Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.analyticsvidhya.com/" target="_blank">
                    <img src={av} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Life Skills Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://getrevup.com/" target="_blank">
                    <img src={revup} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Workshop Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://thementorx.com/" target="_blank">
                    <img src={mentorx} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Food Partners</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a  target="_blank">
                    <img src={scrummys} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a  target="_blank">
                    <img src={fareast} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Travel Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a  target="_blank">
                    <img id="krishna" src={KrishnaAirTravels} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            {/* <div class="spons-section">
              <h3>Merchandise Partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.csm.tech/in/">
                    <img src={csmtech} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a href="https://www.time4education.com/">
                    <img src={time} alt="img" />
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div class="boxes">
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>

      <div class="spons-page"></div>
    </main>
  );
};

export default Page22;
