import React from "react";
import "./styles/Event.css";
import Aos from "aos";
import data from "./Events.json";
import mailsvg from "./images/envelope-fill.svg";
import headset from "./images/headset.svg";
import { renderMarkup } from "react-render-markup";

class Event extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    Aos.init();
    document.body.style.overflowY = "initial";
    let event_details = data[this.props.id];
    let rules = <></>;
    let intro = <>
    { renderMarkup(event_details.Intro) }
    </>;

    for (var i = 0; i < event_details.Rules.length; i++) {
      rules = (
        <>
          {rules}
          <div className="rules" data-aos="fade-right" data-aos-duration="800">
            <span>{i + 1}</span>
            &nbsp;&nbsp; { renderMarkup(event_details.Rules[i]) }
          </div>
        </>
      );
    }
    var problem = <></>;
    if (event_details.Problem != "") {
      problem = (
        <>
          <div
            className="know-more-heading"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            PROBLEM STATEMENT
          </div>
          <div className="know-more-description">
            <div
              className="rules"
              data-aos="fade-right"
              data-aos-duration="800"
              style={{ textAlign: "center"}}
            >
              
              <a
                href={event_details.Problem}
                style={{ textDecoration: "none", color: "#f1356d" }}
                target="_blank"
              >
                Click Here
              </a>
            </div>
          </div>
        </>
      );
    }
    return (
      <div className="guest-page-container">
        <div id="know-more-heading">{event_details.Name}</div>
        <div className="content-know-more">
          <div
            className="know-more-heading"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            INTRODUCTION
          </div>
          <div
            className="know-more-description"
            data-aos="fade-right"
            data-aos-duration="800"
          >
            {intro}
          </div>
          <div
            className="know-more-heading"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            RULES AND REGULATIONS
          </div>
          <div className="know-more-description">{rules}</div>
          {problem}
          
          <div
            className="know-more-heading"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            CONTACT
          </div>
          <div id="know-more-description">
            <div className="eventscontact">
              {/* <div
                className="eventscontactinside"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div>
                  <span className="imgspan">
                    <img src={mailsvg} />
                  </span>
                </div>
                <div>
                  Mail Us at{" "}
                  <span className="eventspan">
                    <a
                      href="mailto:events@nssc.in"
                      style={{ textDecoration: "none", color: "#f1356d" }}
                    >
                      events@nssc.in
                    </a>
                  </span>
                </div>
              </div> */}
              <div
                className="eventscontactinside"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div>
                  <span className="imgspan">
                    <img src={headset} />
                  </span>
                </div>
                <div>
                  Deepiha Sivakumar:<br />
                  <span className="eventspan">
                    <a
                      href="tel:+918825689914"
                      style={{ textDecoration: "none", color: "#f1356d" }}
                    >
                      +91&nbsp;8825689914
                    </a>
                  </span>
                </div>
              </div>
              <div
                className="eventscontactinside"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div>
                  <span className="imgspan">
                    <img src={headset} />
                  </span>
                </div>
                <div>
                  Kaushik Dadi:<br />
                  <span className="eventspan">
                    <a
                      href="tel:+918341741624"
                      style={{ textDecoration: "none", color: "#f1356d" }}
                    >
                      +91&nbsp;8341741624
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Event;
