import React from "react";
import PhotoGallery from "./components/PhotoGallery";
import "./styles/gallery.css";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "2019",
    };
  }
  change(i) {
    this.setState({ year: i });
  }
  render() {
    document.body.style.overflowY = "initial";
    return (
      <div className="container-gallery">
        <p className="heading-gallery">Gallery</p>
        {/* <div className="navbar-members">
          <a
            style={{ padding: "0.5rem 3.5rem" }}
            className="nav-buttons-members"
            onClick={() => this.change("2019")}
          >
            2019
          </a>
          <a
            style={{ padding: "0.5rem 3.5rem" }}
            className="nav-buttons-members"
            onClick={() => this.change("2021")}
          >
            2021
          </a>
        </div>
        <p className="heading-gallery">{this.state.year}</p>
        <br /> */}
        <PhotoGallery year={this.state.year} />
        <br />
        <div class="social">
          <ul class="social-icons-about">
            <li>
              <a href="https://www.facebook.com/nssc.iit" target="_blank">
                <i class="fa fa-facebook "></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/spats_iitkgp" target="_blank">
                <i class="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/national-students'-space-challenge/"
                target="_blank"
              >
                <i class="fa fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Gallery;
