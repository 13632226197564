import React from "react";

const csmtech = "./images/sponsors/2021/CSMtech.png";
const l = "./images/sponsors/2021/L.png";
const d2c = "./images/sponsors/2021/DARE2COMPETE.png";
const gfg = "./images/sponsors/2021/GeeksforGeeks.png";
const ndciame = "./images/sponsors/2021/NDCIAME.png";
const presto = "./images/sponsors/2021/Presto.png";
const redwings = "./images/sponsors/2021/REDWINGS.png";
const sssutms = "./images/sponsors/2021/SSSUTMS.png";
const time = "./images/sponsors/2021/TIMELucknow.jpg";
const townscript = "./images/sponsors/2021/TOWNSCRIPT.png";

const Page21 = () => {
  return (
    <div className="">
      <div class="spons-page">
        <div class="spons-inner">
          <h1>Sponsors 2021</h1>
          <div>
            <div class="spons-section">
              <h3>Title Sponsor</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.redwingsgroup.org/" target = "_blank">
                    <img src={redwings} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Powered by</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://sssutms.co.in/#!/" target = "_blank">
                    <img src={sssutms} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Associate title Sponsor</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://ndcinstitute.com/" target = "_blank">
                    <img src={ndciame} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Gifts and Goodies partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.geeksforgeeks.org/" target = "_blank">
                    <img src={gfg} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a href="http://www.prestogifts.com/" target = "_blank">
                    <img src={presto} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Merchandise partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a>
                    <img src={l} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Payment partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.townscript.com/" target = "_blank">
                    <img src={townscript} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Platform partner</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://dare2compete.com/" target = "_blank">
                    <img src={d2c} alt="img" />
                  </a>
                </div>
              </div>
            </div>
            <div class="spons-section">
              <h3>Event Partners</h3>
              <div class="spons-imgs">
                <div class="spons-img">
                  <a href="https://www.csm.tech/in/" target = "_blank">
                    <img src={csmtech} alt="img" />
                  </a>
                </div>
                <div class="spons-img">
                  <a href="https://www.time4education.com/" target = "_blank">
                    <img src={time} alt="img" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="boxes">
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
};

export default Page21;
