import React, { useEffect } from "react";

function Braitenberg(){
    useEffect(() => {
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSeoNUwLAkps2QN-lB9hEDmTxiorJ5z5F0aP1fnjcdtF3j_hHw/viewform");
      }, []);
      return (
        <div>
        </div>
      );
}

export default Braitenberg;