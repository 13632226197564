import React, { useEffect } from "react";

function space(){
    useEffect(() => {
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLScKKoa6dTLV1U_w8c_bNMt7YjabAeKuk24UPhY4ocYmSuVSjQ/viewform");
      }, []);
      return (
        <div>
        </div>
      );
}

export default space;