import React from "react";
import "./styles/Events_PC.css";
import { useEffect } from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

function EventsPC() {
  if (window.innerWidth < 1101) window.location.reload();

  let input = document.getElementsByClassName("inpu");
  useEffect(() => {
    input[11].checked = true;
  }, []);
  function handleChange(e) {
    console.log(e.target);
    e.target.checked = true;
  }
  function wheel(e) {
    // console.log(e);
    window.addEventListener("wheel", detectscroll(e));
  }
  function detectscroll(e) {
    if (e.deltaY < -100) {
      // console.log("scroll up");
      window.removeEventListener("wheel", detectscroll);
      nextplanet();
      setTimeout(() => {
        window.addEventListener("wheel", detectscroll);
      }, 200);
    } else if (e.deltaY > 100) {
      // console.log("scroll down");
      window.removeEventListener("wheel", detectscroll);
      prevplanet();
      setTimeout(() => {
        window.addEventListener("wheel", detectscroll);
      }, 200);
    }
  }
  function prevplanet() {
    let i = 0;
    while (input[i].checked === false) {
      i++;
    }
    console.log(i);
    if (i === input.length - 1) {
      return;
    } else {
      input[i + 1].checked = true;
    }
  }
  function nextplanet() {
    let i = 0;
    while (input[i].checked === false) {
      i++;
    }
    console.log(i);
    if (i === 0) {
      return;
    } else {
      input[i - 1].checked = true;
    }
  }

  document.body.style.overflow = "hidden";

  return (
    <div>
      <Navbar />
      <div className="App" onWheel={wheel}>
        {/* <div className="planet-list"> */}
        <input
          className="planet12 inpu"
          id="pl12"
          name="planet"
          type="radio"
          onClick={handleChange}
        />
        <label className="plan12 menu" htmlFor="pl12">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              Gyrobot
            </h2>
            <h3>79.5 AU</h3>
          </div>
        </label>
        <input
          className="planet11 inpu"
          id="pl11"
          name="planet"
          type="radio"
          onClick={handleChange}
        />
        <label className="plan11 menu" htmlFor="pl11">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              Mazerunner
            </h2>
            <h3>67.2 AU</h3>
          </div>
        </label>
        <input
          className="planet10 inpu"
          id="pl10"
          name="planet"
          type="radio"
          onClick={handleChange}
        />
        <label className="plan10 menu" htmlFor="pl10">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              ASTROPHOTOGRAPHY
            </h2>
            <h3>55.4 AU</h3>
          </div>
        </label>
        <input
          className="planet9 inpu"
          id="pluto"
          name="planet"
          type="radio"
          onClick={handleChange}
        />
        <label className="pluto menu" htmlFor="pluto">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              DATA ANALYTICS
            </h2>
            <h3>39.5 AU</h3>
          </div>
        </label>
        <input
          className="planet8 inpu"
          id="neptune"
          name="planet"
          type="radio"
          onClick={handleChange}
        />
        <label className="neptune menu" htmlFor="neptune">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              HOVERPOD
            </h2>
            <h3>30.06 AU</h3>
          </div>
        </label>
        <input
          className="planet7 inpu"
          id="uranus"
          name="planet"
          type="radio"
          onClick={handleChange}
        />
        <label className="uranus menu" htmlFor="uranus">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              BRAITENBERG CHALLENGE
            </h2>
            <h3>19.18 AU</h3>
          </div>
        </label>
        <input
          className="planet6 inpu"
          id="saturn"
          name="planet"
          type="radio"
          onClick={handleChange}
        />
        <label className="saturn menu" htmlFor="saturn">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              PAPER PRESENTATION
            </h2>
            <h3>9.539 AU</h3>
          </div>
        </label>
        <input
          className="planet5 inpu"
          id="jupiter"
          name="planet"
          type="radio"
          onClick={handleChange}
          />
        <label className="jupiter menu" htmlFor="jupiter">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              COSMOMATH CHALLENGE
            </h2>
            <h3>5.203 AU</h3>
          </div>
        </label>
        <input
          // checked="checked"
          className="planet4 inpu"
          id="mars"
          name="planet"
          type="radio"
          onClick={handleChange}
          />
        <label className="menu mars" htmlFor="mars">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              SPACE QUIZ
            </h2>
            <h3>1.524 AU</h3>
          </div>
        </label>
        <input
          // checked="checked"
          className="planet3 inpu"
          id="earth"
          name="planet"
          type="radio"
          onClick={handleChange}
        />
        <label className="menu earth" htmlFor="earth">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              SPACE ART
            </h2>
            <h3>1 AU</h3>
          </div>
        </label>
        <input
          // checked="checked"
          className="planet2 inpu"
          id="venus"
          name="planet"
          type="radio"
          onClick={handleChange}
        />
        <label className="menu venus" htmlFor="venus">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div className="pip"></div>
              LIFT OFF
            </h2>
            <h3>0.723 AU</h3>
          </div>
        </label>
        <input
          // checked="checked"
          className="planet1 inpu"
          id="mercury"
          name="planet"
          type="radio"
          onClick={handleChange}
        />
        <label className="menu mercury" htmlFor="mercury">
          <div className="preview"></div>
          <div className="info">
            <h2>
              <div
                onLoad={() => {
                  console.log("loaded successfully");
                }}
                className="pip"
              ></div>
              CASE STUDY
            </h2>
            <h3>0.39 AU</h3>
          </div>
        </label>
        {/* </div> */}
        <div className="solar">
          <div className="solar_systm">
            <div className="planet mercury">
              <div className="planet_description mercury">
                <h1>CASE STUDY</h1>
                <p>
                  Here it is, a chance for you to solve some mysteries of the
                  universe and give some exercise to your grey cells.
                </p>
                <p>
                  <Link to="/CaseStudy">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet venus">
              <div className="planet_description venus">
                <h1>LIFT OFF</h1>
                <p>
                  want to dive through the vastness of the cosmic fluid and
                  observe the darkness of space, what are you waiting for?
                </p>
                <p>
                  <Link to="/LiftOff">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet earth">
              <div className="planet_description earth">
                <h1>SPACE ART</h1>
                <p>
                  Interstellar had a breathtaking presentation of the Black hole
                  ..Right!? Now envision your imagination and make it a reality.
                </p>
                <p>
                  <Link to="/SpaceArt">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet mars">
              <div className="planet_description mars">
                <h1>SPACE QUIZ</h1>
                <p>
                  Do you have enough knowledge to escape from the jaws of death
                  in an alien chase?
                </p>
                <p>
                  <Link to="/SpaceQuiz">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet jupiter">
              <div className="planet_description jupiter">
                <h1>COSMOMATH CHALLENGE</h1>
                <p>
                  From quarks to quasars, Mathematics governs this universe.
                </p>
                <p>
                  <Link to="/CosmomathChallenge">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet saturn">
              <div className="planet_description saturn">
                <h1>PAPER PRESENTATION</h1>
                <p>
                  Einstein's theory of relativity deciphered the trivial
                  mysteries of space.
                </p>
                <p>
                  <Link to="/PaperPresentation">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet uranus">
              <div className="planet_description uranus">
                <h1>BRAITENBERG CHALLENGE</h1>
                <p>
                  Traveling to stars and beyond is next to impossible without a
                  robot like TARS alongside.
                </p>
                <p>
                  <Link to="/BraitenbergChallenge">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet neptune">
              <div className="planet_description neptune">
                <h1>HOVERPOD</h1>
                <p>
                  We are heading close to the era of looking for survival on
                  other planets. It's better to begin the preparation now
                </p>
                <p>
                  <Link to="/Hoverpod">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet pluto">
              <div className="planet_description pluto">
                <h1>DATA ANALYTICS</h1>
                <p>
                  Humanity’s next home might lie in plain sight in the data! The
                  bundles of Astrotech hold valuable data as a humongous pile of
                  information.
                </p>
                <p>
                  <Link to="/DataAnalytics">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay"></div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet plan10">
              <div className="planet_description plan10">
                <h1>ASTROPHOTOGRAPHY</h1>
                <p>
                  The boundless sky is effortlessly narrating its stories
                  through colorful nebulae to clusters of galaxies. So get ready
                  to Click and Capture those stories.
                </p>
                <p>
                  <Link to="/Astrophotography">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay">
              </div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet plan11">
              <div className="planet_description plan11">
                <h1>Maze runner</h1>
                <p>
                Finding a way out of a problem can be challenging. But what if it is a maze of unknown paths and deadends? That would definitely be fun.
                </p>
                <p>
                  <Link to="/Mazerunner">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay">
              </div>
            </div>
          </div>
          <div className="solar_systm">
            <div className="planet plan12">
              <div className="planet_description plan12">
                <h1>Gyrobot</h1>
                <p>
                A significant turning point in robotics history was the development of self-balancing robots.
                </p>
                <p>
                  <Link to="/Gyrobot">
                    <button type="button" className="glow-on-hover">
                      Know More
                    </button>
                  </Link>
                </p>
              </div>
              <div className="overlay">
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img src="./images/kgp_logo.png" id="kgp_logo"></img> */}
    </div>
  );
}

export default EventsPC;
