import React from "react";
import { Link } from "react-router-dom";
import "./styles/Menu_PC.css";
import titleSponsor from "./images/title_sponsor_home.png";
import backgroundGalaxy from "./images/background.jpg";

//var p = [0, 0, 0, 0, 0, 0];
var t = 0;
var s = 0;

class Menu_PC extends React.Component {
  showPlanetDetails() {
    const planet_details = document.getElementsByClassName("planet-details");
    for (let i = 0; i < planet_details.length; i++) {
      planet_details[i].style.visibility = "visible";
      planet_details[i].style.opacity = "1";
    }

    const bottom_text = document.querySelector(".bottom_text_container");
    bottom_text.classList.toggle("active");

    const sponsor_bottom_logo = document.querySelector(".sponsor_bottom_logo");
    sponsor_bottom_logo.classList.toggle("active");

    const nssc_logo = document.querySelector(".nssc_logo");
    nssc_logo.classList.toggle("active");
  }
  pauseit(y) {
    y.style.animationPlayState = "paused";
    let q = getComputedStyle(y).getPropertyValue("--size");
    let z = parseInt(q);
    z *= 1.07;
    let pm = z.toString();
    pm = pm + "px";
    y.style.setProperty("--size", pm);
  }
  start() {
    for (let i = 1; i <= 6; i++) {
      document.getElementById("p" + i).style.animationPlayState = "running";
    }
  }
  componentDidMount() {
    var that = this;
    for (let i = 1; i <= 6; i++) {
      that.pauseit(document.getElementById("p" + i));
    }
    if (s != 0) clearTimeout(s);
    s = setTimeout(that.start, that.props.wait);
  }

  componentWillUnmount() {
    if (window.location.pathname != "/") {
      console.log("unmounted");
      //for (let i = 0; i < 6; i++) clearTimeout(p[i]);
      clearTimeout(t);
    }
  }
  render() {
    // var duration = [1950, 2300, 2750, 1600, 1450, 3120, 1000];
    // for (let i = 0; i < 6; i++) {
    //   if (p[i] != 0) clearTimeout(p[i]);
    //   p[i] = setTimeout(() => {
    //     this.pauseit(document.getElementById("p" + (i + 1)));
    //   }, duration[i] + this.props.wait);
    // }
    if (t != 0) clearTimeout(t);
    t = setTimeout(this.showPlanetDetails, 3400 + this.props.wait);

    document.body.style.overflowY = "hidden";
    return (
      <>
        <div id="container123">
          <Link to="/gallery">
            <div className="planets" id="p1">
              <div className="planet-details">&nbsp;Gallery</div>
            </div>
          </Link>
          <div id="p1_p"></div>
          <Link to="/members">
            <div className="planets" id="p2">
              <div className="planet-details">Members</div>
            </div>
          </Link>
          <div id="p2_p"></div>
          <Link to="/guests">
            <div className="planets" id="p3">
              <div className="planet-details" style={{ left: -10 }}>
                Guest&nbsp;Lectures &<br />
                Workshops
              </div>
            </div>
          </Link>
          <div id="p3_p"></div>
          <Link to="/events">
            <div className="planets" id="p4">
              <div className="planet-details">&nbsp;&nbsp; Events</div>
            </div>
          </Link>
          <div id="p4_p"></div>
          <Link to="/about">
            <div className="planets" id="p5">
              <div className="planet-details">&nbsp;About</div>
            </div>
          </Link>
          <div id="p5_p"></div>
          <Link to="/sponsors">
            <div className="planets" id="p6">
              <div className="planet-details">&nbsp;Sponsors</div>
            </div>
          </Link>
          <div id="p6_p"></div>
          {/* <div className="planets" id="p7"></div>
      <div id="p7_p"></div> */}

          {/* <div className="planets" id="p8"></div>
        <div className="planets" id="p8_8"></div>
        <div id="p8_p"></div> */}

          <div id="sun"></div>
        </div>
        <div className="bottom_text_container">
          <p className="bottom_text">Where Possibilities Begin!</p>
        </div>
        <img className="sponsor_bottom_logo" src={titleSponsor} />
        <img className="nssc_logo" src="./images/nssc_logo.png" />
        {/* <img src={backgroundGalaxy} className="background-galaxy" /> */}
      </>
    );
  }
}

export default Menu_PC;
