import React from "react";
import SignUp from "./components/SignUp";
import "./styles/signup_page.css";

function SignupPage() {
  return (
    <div className="signup-page">
      <SignUp />
    </div>
  );
}

export default SignupPage;
