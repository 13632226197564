import React from "react";
import Fullpage, {
  FullPageSections,
  FullpageSection,
  FullpageNavigation,
} from "@ap.cx/react-fullpage";
import casestudy from "./images/casestudy.png";
import astrophotography from "./images/astrophotography.png";
import cosmomathchallenge from "./images/cosmomathchallenge.png";
import dataanalytics from "./images/dataanalytics.png";
import hoverpod from "./images/hoverpod.png";
import paperpresentation from "./images/paperpresentation.png";
import braitenbergchallenge from "./images/braitenbergchallenge.png";
import spaceart from "./images/spaceart.png";
import spacequiz from "./images/spacequiz.png";
import liftoff from "./images/liftoff.png";
import mazerunner from "./images/mazelogo.png";
import gyrobot from "./images/gyrobot.png"
import "./styles/Events_M.css";
import { Link } from "react-router-dom";

function Event(props) {
  document.body.style.overflowY = "initial";
  return (
    <div className="event-container">
      <img src={props.image} alt="" id="event-img" />
      <div className="content-event">
        <div id="event-heading">{props.title}</div>
        <div id="event-description">{props.description}</div>
        <div id="event-button">
          <Link to={props.link}>
            <button type="button" className="events-glow-on-hover">
              Know More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

class Events_M extends React.Component {
  componentWillUnmount() {
    $(window).scrollTop(0);
  }
  render() {
    if (window.innerWidth > 1100) window.location.reload();

    const sectionStyle = {
      height: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    };

    const sectionStyleFirst = {
      height: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
    };

    return (
      <Fullpage>
        <div className="navigation-dots">
          <FullpageNavigation />
        </div>

        <FullPageSections>
          <FullpageSection style={sectionStyleFirst}>
            <div id="event-page-heading" style={{ marginTop: "120px" }}>
              NSSC EVENTS
            </div>
            <Event
              image={casestudy}
              title="CASE STUDY"
              description="Here it is, a chance for you to solve some mysteries of the universe and give some exercise to your grey cells."
              link="/CaseStudy"
            />
          </FullpageSection>

          <FullpageSection style={sectionStyle}>
            <Event
              image={liftoff}
              title="LIFT OFF"
              description="want to dive through the vastness of the cosmic fluid and observe the darkness of space,  what are you waiting for?"
              link="/LiftOff"
            />
          </FullpageSection>

          <FullpageSection style={sectionStyle}>
            <Event
              image={spaceart}
              title="SPACE ART"
              description="Interstellar had a breathtaking presentation of the Black hole ..Right!?
            Now envision your imagination and make it a reality.
            "
              link="/SpaceArt"
            />
          </FullpageSection>

          <FullpageSection style={sectionStyle}>
            <Event
              image={spacequiz}
              title="SPACE QUIZ"
              description="Do you have enough knowledge to escape from the jaws of death in an alien chase?"
              link="/SpaceQuiz"
            />
          </FullpageSection>

          <FullpageSection style={sectionStyle}>
            <Event
              image={cosmomathchallenge}
              title="COSMOMATH CHALLENGE"
              description="From quarks to quasars, Mathematics governs this universe."
              link="/CosmomathChallenge"
            />
          </FullpageSection>

          <FullpageSection style={sectionStyle}>
            <Event
              image={paperpresentation}
              title="PAPER PRESENTATION"
              description="Einstein's theory of relativity deciphered the trivial mysteries of space. Willing to solve any such mystery?"
              link="/PaperPresentation"
            />
          </FullpageSection>

          <FullpageSection style={sectionStyle}>
            <Event
              image={braitenbergchallenge}
              title="BRAITENBERG CHALLENGE"
              description="Traveling to stars and beyond is next to impossible without a robot  like TARS alongside."
              link="/BraitenbergChallenge"
            />
          </FullpageSection>

          <FullpageSection style={sectionStyle}>
            <Event
              image={hoverpod}
              title="HOVERPOD"
              description="We are heading close to the era of looking for survival on other planets. It's better to begin the preparation now."
              link="/Hoverpod"
            />
          </FullpageSection>

          <FullpageSection style={sectionStyle}>
            <Event
              image={dataanalytics}
              title="DATA ANALYTICS"
              description="Humanity’s next home might lie in plain sight in the data! The bundles of Astrotech hold valuable data as a humongous pile of information."
              link="/DataAnalytics"
            />
          </FullpageSection>
          <FullpageSection style={sectionStyle}>
            <Event
              image={astrophotography}
              title="ASTROPHOTOGRAPHY"
              description="The boundless sky is effortlessly narrating its stories through colorful nebulae to clusters of galaxies. So get ready to Click and Capture those stories."
              link="/Astrophotography"
            />
          </FullpageSection>
          <FullpageSection style={sectionStyle}>
            <Event
              image={mazerunner}
              title="Maze Runner"
              description="Finding a way out of a problem can be challenging. But what if it is a maze of unknown paths and deadends?"
              link="/Mazerunner"
            />
          </FullpageSection>
          <FullpageSection style={sectionStyle}>
            <Event
              image={gyrobot}
              title="Gyrobot"
              description="A significant turning point in robotics history was the development of self-balancing robots."
              link="/Gyrobot"
            />
          </FullpageSection>
        </FullPageSections>
      </Fullpage>
    );
  }
}

export default Events_M;
