import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

var photos;

const PhotoGallery = (para) => {
  //console.log(para.year);
  if (para.year == 2019) {
    photos = [
      {
        src: "./images/2019/IMG_1458.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_1545.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_1582.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_1629.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_1638.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_1725.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_1736.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_1750.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_1751.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_1752.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_2400.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2401.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2425.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2435.JPG",
        width: 4,
        height: 4,
      },
      {
        src: "./images/2019/IMG_2436.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2449.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2454.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2476.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2505.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2506.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2510.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2650.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2668.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2669.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_2690.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2714.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2748.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2770.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_2854.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2855.JPG",
        width: 4,
        height: 3,
      },
      {
        src: "./images/2019/IMG_2856.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2859.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2863.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2865.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2874.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2879.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2893.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2920.JPG",
        width: 3,
        height: 2,
      },
      {
        src: "./images/2019/IMG_2924.JPG",
        width: 3,
        height: 2,
      },
    ];
  } else {
    photos = [];
  }

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div style={{ margin: "0px 20px" }}>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default PhotoGallery;
