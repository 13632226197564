import React, { useEffect } from "react";

function sopf(){
    useEffect(() => {
        window.location.replace("https://unstop.com/p/cosmomath-challenge-national-students-space-challenge-nssc-space-technology-students-society-iit-kharagpur-451549");
      }, []);
      return (
        <div>
        </div>
      );
}

export default sopf;