import React, { useEffect } from "react";

function gyro(){
    useEffect(() => {
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLScElV3wIhN-SAJkf_HpQ-sVaMi1TvR7MRUCTvUx3kFQ4Ckg2A/viewform?usp=sf_link");
      }, []);
      return (
        <div>
        </div>
      );
}

export default gyro;