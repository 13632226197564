import React from "react";
import "./styles/about.css";

function About() {
  document.body.style.overflowY = "initial";
  return (
    <div className="form-wrap-about">
      <div className="about_title">
        <h1>About Us</h1>
      </div>

      <div className="text_area-about">
        <p className="p-about">
          Space Technology Students' Society, or spAts, IIT Kharagpur, in short, is the
          official student body under Kalpana Chawla Space Technology Cell, IIT Kharagpur.
          Founded in 2008 by a small group of young and ambitious undergraduate
          students, spAts had humble beginnings. A small club which was founded
          as a congregation of students who had a common interest called space,
          spAts started off as an ordinary student-run society.
        </p>
        <br />
        <p className="p-about">
          Since its inception, the society has seen multiple changes. From what
          used to be a normal space discussion forum, spAts evolved itself into
          a professionally run society which hosts a plethora of events along
          with the first and largest space technology fest in India, the
          National Students' Space Challenge.
        </p>
        <br />
        <p className="p-about">
          The National Students' Space Challenge (NSSC) is the centrepiece of
          spAts. As the name suggests, NSSC is a national-level fest purely
          dedicated towards space and space technology. The fest had its start
          in the year 2011 when the society decided to independently host a fest
          to provide for a platform to those aspiring engineers and scientists
          of India who needed an opportunity to showcase their talent. In its
          inaugural year, NSSC had just one event: Liftoff. This event which is
          a competition to build the best water rocket with the given
          specifications still remains the highlight of the fest after 11 long
          years. Through the years, various other events such as autonomous and
          manual bot making, space quizzes, case studies and astrophotography
          among others have been added. Besides the events, the National
          Students' Space Challenge is also famous for its supremely successful
          guest lectures, exhibitions and workshops.
        </p>
        <br />
        <p className="p-about">
          The fest sees a huge student participation every year. It has seen a
          literal exponential rise in its participation from the tens to the
          hundreds to the thousands within a span of 11 small years. The whole
          fest is organised and managed completely by the students and also with
          the support of Kalpana Chawla Space Technology Cell, IIT Kharagpur.
        </p>
        <p className="p-about">
          Apart from organising the NSSC, spAts indulges itself in various other
          activities. Every semester it organises at least one open session for
          sky gazing when everyone in the campus is invited to participate and
          learn about astronomy in general. It also releases a semesterly
          periodical called the Moonwalk which discusses all the latest
          happenings in the field of astronomy and space technology. Thousands
          of its copies are distributed between the faculty and the student
          community for free for the sake of spreading awareness. Also, society
          has a reputation for taking up projects and competing in competitions
          across the nation. And most importantly, they hold frequent
          discussions among themselves to dwell deeper into the world of
          cosmology and astronomy.
        </p>
        <br />
      </div>
      <div className="social">
        <ul className="social-icons-about">
          <li>
            <a href="https://www.facebook.com/nssc.iit" target="_blank">
              <i class="fa fa-facebook "></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/spats_iitkgp" target="_blank">
              <i class="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/national-students'-space-challenge/" target="_blank">
              <i class="fa fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default About;
