import React from "react";
import { Link } from "react-router-dom";
import "./styles/Navbar.css";

function Navbar() {
  return (
    <nav className="navbar">
      <Link to="/">
        <img src="./images/nssc.png" className="navbar-logo" />
      </Link>

      <div className="links">
        {/* <Link to="/">Home</Link> */}
        <Link to="/about">About</Link>
        <Link to="/events">Events</Link>
        <Link to="/guests">Guests</Link>
        <Link to="/gallery">Gallery</Link>
        <Link to="/members">Members</Link>
        <Link to="/sponsors">Sponsors</Link>
        {/* <Link to="/merchandise">Merchandise</Link> */}
        <Link to="/contact">Contact</Link>
        {/* <Link to="/login">Login</Link>
        <Link to="/register">Register</Link> */}
      </div>
    </nav>
  );
}

export default Navbar;
