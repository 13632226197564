import React, { useEffect } from "react";

function lift(){
    useEffect(() => {
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSdmseEY03UZS_cWb3sx1nirq6qKTAAJt84TmZgdiWWda0EuCw/viewform");
      }, []);
      return (
        <div>
        </div>
      );
}

export default lift;