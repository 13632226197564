import React, { useEffect } from "react";

function paper(){
    useEffect(() => {
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSc6OczYQ9OKh4JkVI2tNkc2MI-_jIoqggURnexuCr4N3wJhGA/viewform?ts=63444f2a");
      }, []);
      return (
        <div>
        </div>
      );
}

export default paper;