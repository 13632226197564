import React, { useEffect } from "react";

function maze(){
    useEffect(() => {
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSesAuMR7O2PugY0tcDWPmF_vr09ySaflVykLhphU2u-2syo9w/viewform?usp=sf_link");
      }, []);
      return (
        <div>
        </div>
      );
}

export default maze;