import React from "react";
import { Link } from "react-router-dom";
import "./styles/guests.css";
import Aos from "aos";

const greenhouse = "./images/guests/greenhouse.jpg";
const priyanka = "./images/guests/Priyanka.jpg";
const debiprasad = "./images/guests/Debiprasad_Duari.png";
const kanak = "./images/guests/Kanak_Saha.png";

function GuestCard(props) {
  return (
    <div className="guest-container">
      <img
        src={props.image}
        alt=""
        id="guest-img"
        data-aos="fade-left"
        data-aos-duration="800"
      />
      <div className="content-guest">
        <div id="guest-heading" data-aos="fade-down" data-aos-duration="800">
          {props.title}
        </div>
        <div
          id="guest-description"
          data-aos="fade-right"
          data-aos-duration="800"
        >
          {props.description}
        </div>
        <div id="guest-button">
          <Link to={props.name}>
            <button type="button" className="glow-on-hover-guests">
              Know More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

function Guests() {
  Aos.init();
  document.body.style.overflowY = "initial";
  return (
    <div className="guest-page-container">
      <div id="guest-page-heading">Guest Lectures and Workshops</div>
      <GuestCard
        image={greenhouse}
        title="Matt Greenhouse"
        name="/jwst"
        description="Worked on many NASA and ESA flight missions; Mr. Greenhouse is currently the Integrated Science Instrument Module Project Scientist of the engineering marvel James Webb Space Telescope."
      />
      <GuestCard
        image={priyanka}
        title="Priyanka Srivastava"
        name="/perseverance"
        description="One of the nine Indians who made NASA's Perseverance Rover land successfully on the Martian Surface and currently working on Europa Clipper Mission."
      />
      <GuestCard
        image={kanak}
        title="Kanak Saha"
        name="/kanaksaha"
        description="A global team of scientists led by Dr. Kanak Saha, Associate Professor of Astronomy at the Inter-University Centre for Astronomy and Astrophysics (IUCAA), Pune, has discovered one of the earliest galaxies in extreme ultraviolet light."
      />
      <GuestCard
        image= {debiprasad}
        title="Dr. Debiprosad Duari"
        name="/chiefguest"
        description="With years of research and teaching experience in universities like the Manchester Institute of Science & Technology, U. K and Presidency University, Kolkata, Dr. Duari felt the need to keep the citizens of our nation informed about developments in the field of cosmology."
      />
    </div>
  );
}

export default Guests;
