import React from "react";
import emailjs from "@emailjs/browser";
import "./styles/contact.css";
import { useAlert, positions, transitions, types } from "react-alert";

function ContactUs() {
  document.body.style.overflowY = "initial";
  const form = React.useRef();
  const alert = useAlert();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_l0igg9r",
        "template_dmpgkwo",
        form.current,
        "msigs7C-8SMgTwhAz"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert.show(
            "Your Query has been recorded. We will get back to you soon.",
            {
              timeout: 3500,
              type: types.SUCCESS,
              position: positions.TOP_CENTER,
              transition: transitions.FADE,
            }
          );
        },
        (error) => {
          console.log(error.text);
          alert.show(
            "We ran into some error, please try again after some time.",
            {
              timeout: 3500,
              type: types.ERROR,
              position: positions.TOP_CENTER,
              transition: transitions.FADE,
            }
          );
        }
      );
    e.target.reset();
  };
  return (
    <section id="contact-section-wrapper">
      <div className="contact-box-wrapper">
        <div className="contact-info-wrap">
          <div style={{ fontSize: "40px", textAlign: "center" }}>
            Contact Us!
          </div>

          <h3
            className="contact-info-sub-title"
            style={{ textAlign: "center" }}
          >
            Please get in touch with us, in case you have any queries or questions.
          </h3>
          <ul className="contact-info-details">
            <li>
              <i className="fa fa-phone"></i>
              <a href="tel:+917995880289">+91 79958 80289</a>
            </li>
            <li>
              <i className="fa fa-paper-plane"></i>
              <a href="mailto:saisreekar.nssc@gmail.com">
                saisreekar.nssc@gmail.com
              </a>
            </li>
            <li>
              <i className="fa fa-globe"></i>
              <a href="https://www.nssc.in/" target="_blank">
                www.nssc.in
              </a>
            </li>
          </ul>
          <ul className="contact-social-icons">
            <li>
              <a href="https://www.facebook.com/nssc.iit" target="_blank">
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/spats_iitkgp" target="_blank">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/national-students'-space-challenge/"
                target="_blank"
              >
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="contact-form-wrap">
          <form ref={form} onSubmit={sendEmail}>
            <div className="line">
              <h2
                className="contact-lineUp contact-form-title"
                style={{ textAlign: "center" }}
              >
                Send Us Your Query!!
              </h2>
            </div>
            <div className="contact-form-fields">
              <div className="contact-form-group">
                <input
                  type="text"
                  className="fname"
                  name="fname"
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="contact-form-group">
                <input
                  type="text"
                  className="lname"
                  name="lname"
                  placeholder="Last Name"
                />
              </div>
              <div className="contact-form-group">
                <input
                  type="email"
                  className="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="contact-form-group">
                <input
                  type="tel"
                  name="phonenumber"
                  placeholder="Phone No."
                  pattern="[0-9]{10}"
                  maxLength="10"
                  title="Ten digits number"
                  required
                />
              </div>
              <div className="contact-form-group">
                <textarea
                  name="message"
                  id=""
                  placeholder="Write your message"
                  required
                ></textarea>
              </div>
            </div>
            <button type="submit">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
