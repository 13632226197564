import React, { useEffect } from "react";

function volunteer(){
    useEffect(() => {
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSfbPxKCt8UZ4OFv8P7LgomMoNdFRnMQGBCdCz10Q_k900_VcQ/viewform?usp=sf_link");
      }, []);
      return (
        <div>
        </div>
      );
}

export default volunteer;



