import React, { useState } from "react";
import "./styles/sponsors.css";
import Page22 from "./Page22";
import Page21 from "./Page21";
import Page19 from "./Page19";

function Sponsors() {
  const [flag19, setFlag19] = useState(false);
  const [flag21, setFlag21] = useState(false);
  const [flag22, setFlag22] = useState(true);

  const handleClick19 = () => {
    setFlag19(true);
    setFlag21(false);
    setFlag22(false);
  };

  const handleClick21 = () => {
    setFlag19(false);
    setFlag21(true);
    setFlag22(false);
  };

  const handleClick22 = () => {
    setFlag19(false);
    setFlag21(false);
    setFlag22(true);
  };

  document.body.style.overflowY = "initial";
  return (
    <main class="app">
      <div class="spons-topnav">
        <a onClick={handleClick19} class="year">
          2019
        </a>
        <a onClick={handleClick21} class="year">
          2021
        </a>
        <a onClick={handleClick22} class="year">
          2022
        </a>
      </div>

      {flag22 && <Page22 />}
      {flag21 && <Page21 />}
      {flag19 && <Page19 />}
    </main>
  );
}

export default Sponsors;
