import React, { useEffect } from "react";

function data(){
    useEffect(() => {
        window.location.replace("https://datahack.analyticsvidhya.com/contest/nssc-2022/");
      }, []);
      return (
        <div>
        </div>
      );
}

export default data;