import React, { useEffect } from "react";

function study(){
    useEffect(() => {
        window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSce1F-7kTvVJEkH9ZFmiMB_fU-E3yxbmlY1psVrZTpGXgg4KQ/viewform?ts=63444f1d");
      }, []);
      return (
        <div>
        </div>
      );
}

export default study;