import React from "react";
import { Link } from "react-router-dom";
import "./styles/Navbar_M.css";

function Navbar_M() {
  const menu_toggle = () => {
    const menu = document.querySelector("#mobile-menu");
    const menuLinks = document.querySelector(".navbar_M_menu");
    menu.classList.toggle("is-active");
    menuLinks.classList.toggle("active");
  };

  return (
    <nav className="navbar_M">
      <div className="navbar_M_container">
        <Link to="/">
          <img src="./images/nssc.png" className="navbar-M-logo" />
        </Link>
        <div className="navbar_M_toggle" id="mobile-menu" onClick={menu_toggle}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <ul className="navbar_M_menu">
          <li className="navbar_M-item">
            <Link className="navbar_M-links" onClick={menu_toggle} to="/">
              Home
            </Link>
          </li>
          <li className="navbar_M-item">
            <Link className="navbar_M-links" onClick={menu_toggle} to="/about">
              About
            </Link>
          </li>
          <li className="navbar_M-item">
            <Link className="navbar_M-links" onClick={menu_toggle} to="/events">
              Events
            </Link>
          </li>
          <li className="navbar_M-item">
            <Link className="navbar_M-links" onClick={menu_toggle} to="/guests">
              Guests
            </Link>
          </li>
          <li className="navbar_M-item">
            <Link
              className="navbar_M-links"
              onClick={menu_toggle}
              to="/gallery"
            >
              Gallery
            </Link>
          </li>
          <li className="navbar_M-item">
            <Link
              className="navbar_M-links"
              onClick={menu_toggle}
              to="/members"
            >
              Members
            </Link>
          </li>
          <li className="navbar_M-item">
            <Link
              className="navbar_M-links"
              onClick={menu_toggle}
              to="/sponsors"
            >
              Sponsors
            </Link>
          </li>
          {/* <li className="navbar_M-item">
            <Link
              className="navbar_M-links"
              onClick={menu_toggle}
              to="/merchandise"
            >
              Merchandise
            </Link>
          </li> */}
          <li className="navbar_M-item">
            <Link
              className="navbar_M-links"
              onClick={menu_toggle}
              to="/contact"
            >
              Contact
            </Link>
          </li>
          {/* <li className="navbar_M-item">
            <span id="reglog"><Link
              className="login-reg"
              onClick={menu_toggle}
              to="/login"
            >
              Login
            </Link>/
            <Link
              className="login-reg"
              onClick={menu_toggle}
              to="/register"
            >
              Register
            </Link></span>
          </li> */}
          {/* <li className="navbar_M-item"> */}
          {/* </li> */}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar_M;
